<template>
   <div class="content">
    <div class="pt-4" style="padding-top:40px;padding-left:40px">
      <el-breadcrumb separator="/">
          <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item><a href="/">库存管理</a></el-breadcrumb-item>
        </el-breadcrumb>
        <div>
           <el-row>
             <el-col :span="8">
                <div class="discente">
                  <div>
                    商品品类
                  </div>
                  <div>
                    {{dataArr.clothingCategoryTotal}} 款
                  </div>
                </div>
             </el-col>
             <el-col :span="8">
               <div class="discente">
                  <div>
                    商品数量
                  </div>
                  <div>
                    {{dataArr.itemTotal}} 件
                  </div>
                </div>
             </el-col>
             <el-col :span="8">
               <div class="discente">
                  <div>
                    总金额
                  </div>
                  <div>
                    {{dataArr.totalAmount / 100}} 元
                  </div>
                </div>
             </el-col>
           </el-row>
        </div>
        <div class="mt-4">
             <el-form :inline="true" :model="searchInfo" class="search-area scrch-style">
                    <el-form-item label="商品编号"  label-width="100px">
                       <el-input v-model="itemName"></el-input>
                    </el-form-item>
                    <el-form-item label="商品状态"  label-width="100px">
                      <el-select clearable v-model="saleStatus" placeholder="请选择" @change="typechane">
                                <el-option value="0" label="全部"></el-option>
                                <el-option value="1" label="上架"></el-option>
                                <el-option value="2" label="下架"></el-option>
                            </el-select>
                       <!-- <el-input v-model="itemName"></el-input> -->
                    </el-form-item>
                    <el-form-item label="商品品类"  label-width="100px">
                        <el-select v-model="pinlei" filterable clearable placeholder="请选择">
                            <el-option
                              v-for="item in styleListpinlei"
                              :key="item.optionValue"
                              :label="item.optionShow"
                              :value="item.optionValue">
                            </el-option>
                          </el-select>
                    </el-form-item>
                    <el-form-item label="买手货架"  label-width="100px">
                          <el-select clearable v-model="buyUserId" filterable clearable placeholder="请选择">
                                <el-option
                                  v-for="item in burUsersList"
                                  :key="item.userId"
                                  :label="item.user.userName"
                                  :value="item.userId">
                                </el-option>
                          </el-select>
                    </el-form-item>
                    <el-button type="danger" @click="search" class="" >查询</el-button>
            </el-form>
             <el-table
                class="table-style"
                    :data="tableData"
                    height="550"
                    style="width: 100%">
                    <!-- <el-table-column
                    align="center"
                      prop="date"
                      label="日期"
                      >
                    </el-table-column> -->
                    <el-table-column
                    align="center"
                      prop="itemName"
                      sortable
                      label="商品编码"
                      >
                    </el-table-column>
                     <el-table-column
                    align="center"
                      prop="mainImage"
                      sortable
                      label="商品图片"
                      >
                      <template slot-scope="scope">
                        <div>
                          <img :src="scope.row.mainImage" alt="" srcset="" style="width:50px;height:50px">
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="clothingCategory"
                      sortable
                      label="品类"
                      >
                    </el-table-column>
                    <el-table-column
                    align="center"
                      prop="supplyPrice"
                      sortable
                      label="成本价"
                      >
                      <template slot-scope="scope">
                      <div>
                        {{(scope.row.supplyPrice / 100).toFixed(2)}}元
                      </div>
                    </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      sortable
                      prop="onSkuNum"
                      label="在库数"
                      >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="outSkuNum"
                      sortable
                      label="出库数">
                    </el-table-column>
                    <el-table-column
                      align="center"
                      prop="skuNum"
                      sortable
                      label="总库存">
                    </el-table-column>
                     <el-table-column
                      align="center"
                      prop="amount"
                      sortable
                      label="总金额">
                       <template slot-scope="scope">
                      <div>
                        {{(scope.row.totalAmount / 100).toFixed(2)}}元
                      </div>
                    </template>
                    </el-table-column>
                     <el-table-column
                      align="center"
                      prop="buyUser"
                      sortable
                      label="买手货架">
                    </el-table-column>
                   <el-table-column
                      fixed="right"
                      label="操作"
                      width="120">
                      <template slot-scope="scope">
                        <div @click="goodsDetails(scope.row)" class="updateBtn">
                          查看
                        </div>
                        <div @click="stockInfo(scope.row)" class="updateBtn">
                          库存
                        </div>
                      </template>
                    </el-table-column>
            </el-table>
            <div class="pager-style">
                      <el-pagination layout="total, prev, pager, next"
                                  :total="totalCount"
                                  :page-size="pageSize"
                                  :current-page="curPageNum"
                                  @current-change="handlePagerChange">
                      </el-pagination>
                  </div>
        </div>
    </div>
     <el-dialog :visible.sync="showPop" width="60%" title="新增用户属性" :before-close="handleDialogClose" :close-on-click-modal="false">

     </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      status:'1',
      itemName:'',
      totalCount:0,
      pageSize:10,
      buyUserId:'',
      showPop:false,
      curPageNum:1,
      dataArr:{},
       searchInfo:{
         attribute:1
       },
       saleStatus:'0',
        styleListpinlei:[],
        pinlei:'',
        burUsersList:[],
      tableData:[]
    }
  },
  created(){
    this.getData()
    this.getNum()
    this.getItemAttrCfgByCategoryId()
    this.getBuyerUsers()
  },
  methods:{
      handlePagerChange(){

      },
      handleDialogClose(){

      },
      goodsDetails(e){
        console.log(e)
          this.$router.push(`goodsDetails?id=${e.itemId}`)
      },
      stockInfo(e){
         this.$router.push(`stockInfo?id=${e.itemId}`)
      },
      getItemAttrCfgByCategoryId () {
          this.$get('/itemManager/getItemAttrCfgByCategoryId',{categoryId: '156977743619461'}).then(res =>{
                    let data = res.data.list
            // let styleListpinlei = 
            // let styleListfengge = 
          this.styleListpinlei = this.addchend(data[3].attrOptionList)
          console.log(this.styleListpinlei)
          })
      },
      addchend(list){
        let a = list.map(ele =>{
          // console.log(ele)
          ele.chend = false
          return ele
        })
        return a
      },
      getData(){
        let params ={
          pageIndex:this.curPageNum,
          pageSize:this.pageSize,
          status:this.status,
          itemName:this.itemName,
          buyUserId:this.buyUserId,
          clothingCategory:this.pinlei,
          saleStatus:this.saleStatus
        }
        this.$post(`/skuCodeManage/searchSkuCodeManageList`,params).then(res =>{
          console.log(res)
          this.tableData = res.data.list
          this.totalCount = res.totalCount
        })
      },
      handlePagerChange(value){
        console.log()
        this.curPageNum = value
        this.getData()
      },
      typechane(){

      },
      getNum(){
        let params ={
          pageIndex:this.curPageNum,
          pageSize:this.pageSize,
          status:this.status,
          saleStatus:this.saleStatus
        }
        this.$get(`/skuCodeManage/countSkuCodeManage`,params).then(res =>{
          console.log(res)
          this.dataArr = res.data
          // this.tableData = res.data.list
          // this.totalCount = res.totalCount
        })
      },
      getBuyerUsers(){
          this.$post(`/userManage/getBuyerUsers`,{}).then(res =>{
              this.burUsersList = res.data.list
          })
      },
      search(){
        this.curPageNum = 1
        this.getData()
        this.getNum()
      }
  }
}
</script>
<style scoped lang="scss">
.discente{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
